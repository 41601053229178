export const mapToObject = (arr, idKey = 'id') =>
  arr.reduce((accObject, item) => {
    accObject[item[idKey]] = item;
    return accObject;
  }, {});

export const setProp = (obj, strPath, value, immutably = false, separator = '.') => {
  if (immutably) {
    obj = Array.isArray(obj) ? [...obj] : { ...obj };
  }
  const original = obj;
  const path = strPath.split(separator);
  while (path.length > 1) {
    const lastLevel = obj;
    const key = path.shift();
    if (immutably) {
      obj = Array.isArray(obj[key]) ? [...obj[key]] : { ...obj[key] };
    } else {
      obj = obj[key];
    }
    lastLevel[key] = obj;
  }
  obj[path.shift()] = value;
  return original;
};

export const getProp = (obj, strPath, separator = '.') => {
  const parts = strPath.split(separator);
  for (const k of parts) {
    obj = obj[k];
    if (!obj) return;
  }
  return obj;
};

export const objectMap = (object, func) => {
  const arr = [];
  for (const key in object) {
    if (!Object.hasOwn(object, key)) continue;
    const value = object[key];
    arr.push(func(value, key, arr.length));
  }
  return arr;
};

export const extractQueryParams = (queryString) =>
  queryString
    .replace('?', '')
    .split('&')
    .reduce((agg, cur) => {
      const parts = cur.split('=');
      agg[parts[0]] = parts[1] || '';
      return agg;
    }, {});
